<template>
  <b-card
  >
    <div class="m-2">
      <b-overlay
          :show="show"
          no-wrap
      />
      <b-row>
        <b-col
            lg="12"
        >
          <b-form @submit.prevent="sendInventory(send)">
            <b-row>
              <b-col cols="12" lg="6">
                <b-form-group
                    label="Fecha de transaccion"
                >
                  <flat-pickr
                      v-model="send.scheduled_date"
                      disabled=""
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:m',locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febrero', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
                  />

                </b-form-group>
              </b-col>
              <b-col
                  cols="12" lg="6"
              >
                <b-form-group
                    label="Paciente"

                >
                  <v-select
                      style=" border-radius: 1px !important;"
                      v-model="selectPartner"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="value"
                      @input="searchByPartner()"
                      :options="optionsPartners"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12" lg="6"
              >
                <b-col cols="12">
                  <div class="demo-inline-spacing">
                    <h4>Ubicación:</h4>
                    <b-form-radio   v-for="(location, index) in optionsLocation"        :key="index"
                        v-model="send.location_id"
                        :value="location.id"
                    >
                      {{location.value}}
                    </b-form-radio>

                  </div>
                </b-col>
              </b-col>
              <b-col  cols="12" lg="6">
                <b-form-group
                    label="Documento de origen"
                >
                  <b-form-input
                      v-model="send.origin"
                      placeholder="Documento de origen"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" class="mt-1">
                <div align="right">
                  <b-button
                      style="width: 25%"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                  >
                    Guardar
                  </b-button>
                </div>
              </b-col>

            </b-row>
          </b-form>
        </b-col>


      </b-row>

    </div>
  </b-card>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  VBTooltip,
  BOverlay,
  BModal,
  VBModal, BFormCheckbox, BFormCheckboxGroup, BFormRadio, BLink

} from 'bootstrap-vue'
import vSelect from "vue-select";
import {mapActions, mapGetters} from "vuex";
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {VueGoodTable} from 'vue-good-table'
import ModalAppointment from "@/components/Appointments/ModalAppointment";
import flatPickr from "vue-flatpickr-component";
import moment from 'moment';
export default {
  name: "AddInventory",
  components: {
    BLink,
    BFormRadio,
    VueGoodTable,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    VBTooltip, BModal,
    VBModal,
    ModalAppointment, flatPickr, BFormCheckbox, BFormCheckboxGroup, vSelect, BForm
  },
  data() {
    return {
      show: false,
      selectPartner: {id: "", value: "Seleccione a un Paciente"},
      selectLocation: {id: "", value: "Seleccione una ubicacion"},
      optionsPartners: [],
      optionsLocation: [],
      optionsPartnersAll: [],
      send: {
        patient_id: null,
        location_id: null,
        origin: null,
        scheduled_date: new Date(this.dateToday(moment().format('YYYY-MM-DD HH:mm'))),
      }

    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    'b-modal': VBModal,
  },
  async created() {
    await this.findPartners();
    await this.findLocations();
  },
  methods: {
    ...mapActions('inventory', ['findListPartners','createInventory','findAllLocations','findAllLocationsRequired']),
    async verifyData() {
      if (!this.send.patient_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Paciente es requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }

      if (!this.send.scheduled_date) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Fecha de transaccion  es requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }

      if (!this.send.location_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Ubicación  es requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false;
      }
      return true
    },
    async sendInventory(data) {
      const verifyDataResponse = await this.verifyData();
      if (!verifyDataResponse) return
      this.show = true
      const dataSend = {
        patient_id: this.send.patient_id,
        location_id: this.send.location_id,
        location_dest_id: 5,
        picking_type_id: this.send.location_id === 37 ?41 :2,
        origin: this.send.origin,
        scheduled_date: this.sumHoursOdooV(this.send.scheduled_date)
      }
      const response = await this.createInventory(dataSend);
      if (response.msg){

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se creo correctamente',
            icon: 'EditIcon',
            variant: 'success',
          },
        });
        this.show = false
        await this.$router.push(`/editar_inventario_salida/${response.msg}`)
      }else{
        this.show = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al crear contacte con el administrador',
            icon: 'error',
            variant: 'warning',
          },
        });
      }


    },
    async clearDate() {
      this.send.scheduled_date = null;
    },
    async searchByPartner() {
      this.send.patient_id = this.selectPartner ? this.selectPartner.id : null;
    },

    async searchByLocation() {
      this.send.location_id = this.selectLocation ? this.selectLocation.id : null;
    },
    async findPartners() {
      const response = await this.findListPartners();
      for (const a of response.partners) {
        this.optionsPartners.push({
          id: a.id,
          value: a.name
        })
        this.optionsPartnersAll.push({
          id: a.id,
          name: a.name,
        })
      }
    },
    async findLocations() {
      const response = await this.findAllLocationsRequired();
      for (const a of response.locations) {
        this.optionsLocation.push({
          id: a.id,
          value: a.complete_name
        })
      }
    },
  },


}
</script>

<style scoped>

</style>